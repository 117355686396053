const Routes = {
  "oferta-educativa": [
    { params:
      {
        "level": "bachillerato",
        "levelRoute": "oferta-educativa/bachillerato",
        "config": {
          title: "Bachillerato",
          promo: {
            urlImage: {
              mobile: "https://assets.staging.bedu.org/UANE/oferta_bachillerato_movil_1f0d5cd934.jpg",
              desktop: "https://assets.staging.bedu.org/UANE/oferta_bachillerato_desk_654176bdbd.jpg"
            },
            text: "",
            icon: "arrow_forward",
            color: "#99E5E2",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Bachilleratos",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
        ] 
      }
    },
    { params:
      {
        "level": "licenciatura",
        "levelRoute": "oferta-educativa/licenciatura",
        "config": {
          title: "Licenciaturas",
          promo: {
            urlImage: {
              mobile: "https://assets.staging.bedu.org/UANE/oferta_carreras_movil_b12eb60e44.jpg",
              desktop: "https://assets.staging.bedu.org/UANE/oferta_carreras_desk_4ef261dd15.jpg"
            },
            text: "",
            icon: "arrow_forward",
            color: "#F6B5AF",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Licenciaturas",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
      
        ]
      }
    },
    { params:
      {
        "level": "especialidad",
        "levelRoute": "oferta-educativa/especialidad",
        "config": {
          title: "Especialidades",
          promo: {
            urlImage: {
              mobile: "https://assets.staging.bedu.org/UANE/oferta_especialidades_movil_732aee5340.jpg",
              desktop: "https://assets.staging.bedu.org/UANE/oferta_especialidades_desk_b96619eea0.jpg"
            },
            text: "",
            icon: "arrow_forward",
            color: "#B9C3C8",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Especialidades",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
        ] 
      }
    },
    { params:
      {
        "level": "maestria",
        "levelRoute": "oferta-educativa/maestria",
        "config": {
          title: "Maestrías",
          promo: {
            urlImage: {
              mobile: "https://assets.staging.bedu.org/UANE/oferta_maestrias_movil_1b1ed5735a.jpg",
              desktop: "https://assets.staging.bedu.org/UANE/oferta_maestrias_desk_fd2e5b8460.jpg"
            },
            text: "",
            icon: "arrow_forward",
            color: "#E94537",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Maestrias",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
        ] 
      }
    },
    { params:
      {
        "level": "doctorado",
        "levelRoute": "oferta-educativa/doctorado",
        "config": {
          title: "Doctorado",
          promo: {
            urlImage: {
              mobile: "https://assets.staging.bedu.org/UANE/oferta_doctorados_movil_247423d2d0.jpg",
              desktop: "https://assets.staging.bedu.org/UANE/oferta_doctorados_desk_e6f5d1e1ac.jpg"
            },
            text: "",
            icon: "arrow_forward",
            color: "#A57C1C",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Doctorados",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
        ] 
      }
    }
  ],
  "extension-universitaria": { 
    params: {
      "level": "extension-universitaria",
      "config": {
        title: "Extensión Universitaria",
        promo: {
          urlImage: {
            mobile: "https://assets.staging.bedu.org/UANE/oferta_educacion_continua_movil_1c7642bed7.jpg",
            desktop: "https://assets.staging.bedu.org/UANE/oferta_educacion_continua_desk_a06f62f5fa.jpg"
          },
          text: "",
          icon: "arrow_forward",
          color: "#6F7C83",
          opacity: "multiply",
          height: "282px",
          enable: true,
          nobackground: false
        },
        banner: {
          image: {
            src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
            alt: "alumnos"
          },
          description: {
            title: "Extensión Universitaria",
            subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          }
        },
        head: {
          title: "",
          description: "",
        },
      },
      "programs": [] 
    }
  },
  "faq": [
    { params: { "section": "general" } },
    { params: { "section": "modalidad-presencial" } },
    { params: { "section": "modalidad-online" } },
    { params: { "section": "modalidad-flex" } },
  ],
  "blog": [
    { params:
      {
        "level": "entrada",
        "levelRoute": "extension-universitaria/",
        "config": {
          title: "entradas de blog",
          head: {
            title: "",
            description: "",
          },
        },
        "entries": [
          { params: { "entry": "convocatoria-investigacion-1" } },
          { params: { "entry": "convocatoria-investigacion-2" } },
          { params: { "entry": "convocatoria-investigacion-3" } },
          { params: { "entry": "convocatoria-investigacion-4" } },
          { params: { "entry": "convocatoria-investigacion-5" } },
          { params: { "entry": "convocatoria-investigacion-6" } },
          { params: { "entry": "convocatoria-investigacion-7" } },
          { params: { "entry": "convocatoria-investigacion-8" } },
          { params: { "entry": "convocatoria-investigacion-9" } },
          { params: { "entry": "convocatoria-investigacion-10" } },
          { params: { "entry": "convocatoria-investigacion-11" } },
          { params: { "entry": "convocatoria-investigacion-12" } },
        ] 
      }
    },
  ]
}

export default Routes